import React from 'react';
import { Container } from 'react-bootstrap';
import { urlApi, useFullApi } from '../Constants/Global';
import SectionHeader from '../Components/SectionHeader';
import ContentDisplay from '../Components/ContentDisplay';

const ArticleView = ({ contents }) => {
  console.log(contents)
  const storage = useFullApi ? `${urlApi}/storage/article/` : `../${urlApi}/storage/article/`;

  return (
    <>
      <SectionHeader topic={contents?.content?.item?.name} textColor={'text-white'} textAlign={'top-50 start-50 text-center'} />
      <Container className='mt-5 mb-5 pt-5 pb-0'>
        <ContentDisplay contents={contents?.content?.contents} storage={storage} lang={contents.lang} />
      </Container>
    </>
  )
}

ArticleView.defaultProps = {
  contents: {
    content: {
      item: {},
      contents: []
    }
  }
}

export default ArticleView