import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { MessengerChat } from 'react-messenger-chat-plugin';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Styles/core.css';
import { baseName } from './Constants/Global';
import favicon from './Medias/Logos/favicon.png';
import SiteHelmet from './Components/SiteHelmet';
import MainMenu from './Components/MainMenu';
import MainFooter from './Components/MainFooter';
import Home from './Pages/Home';
import Article from './Pages/Article';
import ArticleView from './Pages/ArticleView';
import ObjRouteManager from './Components/ObjRouteManager';
import ScrollToTop from './Components/ScrollToTop';

const App = () => {
  const [datas, setDatas] = useState({});

  const routeChangeHandler = (contents) => {
    console.log('routeChangeHandler', contents);
    setDatas(contents);
  }

  return (
    <>
      <BrowserRouter basename={baseName}>
        <ObjRouteManager changeHandler={routeChangeHandler}></ObjRouteManager>
        <ScrollToTop>
          <SiteHelmet favicon={favicon} author="Aber" title={datas.title} />
          <MainMenu contents={datas} />
          <Container fluid className='px-0'>
            <Routes>
              <Route path="/" element={<Home contents={datas} />} />
              <Route path="/Article" element={<Article contents={datas} />} />
              <Route path="/Article/:slug" element={<ArticleView contents={datas} />} />
            </Routes>
          </Container>
          <MainFooter contents={datas} />
        </ScrollToTop>
      </BrowserRouter>
    </>
  )
}

export default App