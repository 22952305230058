import React, { useContext } from 'react';
import ApiDataContext from '../Components/ObjRouteManager';

const Article = () => {
  const apiData = useContext(ApiDataContext);
  console.log("Article", apiData);

  return (
    <div>Article</div>
  )
}

export default Article