import React from 'react';
import { Col } from 'react-bootstrap';

const ContentYoutube = ({url, title, width, height, size}) => {
  return (
    <Col xs={12} sm={12} md={12} lg={size} xl={size} xxl={size} className='text-center'>
      {url !== '' ? (
        <iframe width={width} height={height} src={url} title={title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      ) : ('')}
    </Col>
  )
}

ContentYoutube.defaultProps = {
  url: '', 
  title: '', 
  width: '100%', 
  height: '400', 
  size: 12
}

export default ContentYoutube