import React, { useState } from 'react';
import { urlApi } from '../Constants/Global';
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { BiEnvelope } from 'react-icons/bi';

const FormContact = ({ lang }) => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    subject: '',
    message: ''
  });
  const [modalOption, setModalOption] = useState({
    title: '',
    message: '',
    buttonLabel: 'Close',
    show: false
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  }

  const handleSubmit = (event) => {
    console.log("handleSubmit", formData);
    event.preventDefault();

    if (formData.name === '') {
      setModalOption({
        ...modalOption,
        title: lang === 'th' ? 'พบข้อผิดพลาด' : 'Failed',
        message: lang === 'th' ? 'โปรดกรอกชื่อ' : 'Please provide your name',
        show: true
      });
    } else if (formData.phone === '') {
      setModalOption({
        ...modalOption,
        title: lang === 'th' ? 'พบข้อผิดพลาด' : 'Failed',
        message: lang === 'th' ? 'โปรดกรอกเบอร์โทรศัพท์หรืออีเมลสำหรับการติดต่อกลับ' : 'Please provide your phone number or email address for us to contact you.',
        show: true
      });
    } else if (formData.subject === '') {
      setModalOption({
        ...modalOption,
        title: lang === 'th' ? 'พบข้อผิดพลาด' : 'Failed',
        message: lang === 'th' ? 'โปรดกรอกเรื่อง' : 'Please provide subject.',
        show: true
      });
    } else {
      var form_data = new FormData();
      form_data.append("subject", formData.subject);
      form_data.append("name", formData.name);
      form_data.append("phone", formData.phone);
      form_data.append("message", formData.message);

      var url = urlApi + '/?module=web&call=message-submit';

      fetch(url, {
        method: 'POST',
        body: form_data
      })
        .then(res => res.json())
        .then(result => {
          console.log("messageSent", result.returnStatus.code, result);

          if (result.returnStatus.code === "0000") {
            setModalOption({
              ...modalOption,
              title: lang === 'th' ? 'ดำเนินการเรียบร้อย' : 'Successful',
              message: lang === 'th' ? 'ได้รับข้อความเรียบร้อย ขอบคุณที่ติดต่อหาเรา' : 'Message received successfully. Thank you for contacting us.',
              show: true
            });
            setFormData({
              name: '',
              phone: '',
              email: '',
              subject: '',
              message: ''
            });
          } else {
            setModalOption({
              ...modalOption,
              title: lang === 'th' ? 'ดำเนินการไม่สำเร็จ' : 'Failed',
              message: lang === 'th' ? 'ขออภัยไม่สามารถส่งข้อความได้' : 'Sorry, unable to send the message.',
              show: true
            })
          }
        });
    }
  }

  const handleClose = () => {
    console.log("handleClose");
    setModalOption({
      ...modalOption,
      show: false
    })
  }

  return (
    <>
      <Form className="form-contact" onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} md={7}>
            <Form.Floating className="mb-3">
              <Form.Control
                id="name"
                name="name"
                type="text"
                placeholder={lang === 'th' ? "ชื่อ-นามสกุล" : 'Name'}
                value={formData.name}
                onChange={handleInputChange}
              />
              <label htmlFor="name">{lang === 'th' ? "ชื่อ-นามสกุล *" : 'Name *'}<span className="restrict"></span></label>
            </Form.Floating>
          </Col>
          <Col xs={12} md={5}>
            <Form.Floating className="mb-3">
              <Form.Control
                id="phone"
                name="phone"
                type="text"
                placeholder={lang === 'th' ? "เบอร์โทรศัพท์" : 'Phone'}
                value={formData.phone}
                onChange={handleInputChange}
              />
              <label htmlFor="phone">{lang === 'th' ? "เบอร์โทรศัพท์หรืออีเมล์ *" : 'Phone or email *'}<span className="restrict"></span></label>
            </Form.Floating>
          </Col>
          <Col xs={12}>
            <Form.Floating className="mb-3">
              <Form.Control
                id="subject"
                name="subject"
                type="text"
                placeholder={lang === 'th' ? 'เรื่อง' : 'Subject'}
                value={formData.subject}
                onChange={handleInputChange}
              />
              <label htmlFor="subject">{lang === 'th' ? 'เรื่อง *' : 'Subject *'}<span className="restrict"></span></label>
            </Form.Floating>
          </Col>
          <Col xs={12}>
            <Form.Floating className="mb-3">
              <Form.Control
                id="message"
                name="message"
                as="textarea"
                placeholder={lang === 'th' ? 'ข้อความ' : 'Message'}
                style={{ height: '100px' }}
                value={formData.message}
                onChange={handleInputChange}
              />
              <label htmlFor="message">{lang === 'th' ? 'ข้อความ' : 'Message'}</label>
            </Form.Floating>
          </Col>
          <Col xs={12} className="text-start">
            <Button type="submit" className="btn px-5 bg-color-secondary border-0">
              <BiEnvelope className="me-2" size={30} /> {lang === 'th' ? 'ส่งข้อความ' : 'SEND MESSAGE'}
            </Button>
          </Col>
        </Row>
      </Form>

      <Modal size="sm" show={modalOption.show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="itx-primary">{modalOption.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="itx-dark">
          {modalOption.message}
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn ibt-primary px-4" onClick={handleClose}>
            {modalOption.buttonLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

FormContact.defaultProps = {
  lang: 'th'
};

export default FormContact