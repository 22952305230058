import React, { useState } from 'react';
import { urlApi } from '../../Constants/Global';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function HomeArticle({ contents }) {
  const [selectCategory, setSelectCategory] = useState('');
  const maxItem = 12;

  const handleClick = (sel_item) => {
    console.log("handleClick", sel_item);
    setSelectCategory(sel_item);
  }

  return (
    <section className="pt-5 pb-5">
      <Container className='pt-5 pb-5'>
        <>
          <Row className='mb-5'>
            <Col className='text-center fs-1'>
              <Link to='/News' className="text-white text-decoration-none">
                News & Content
              </Link>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col className="text-center">
              <ul className="p-0 m-0">
                <li className="d-inline-block px-2">
                  <Button variant='outline-light' onClick={() => handleClick('')} className="rounded-pill fs-4 px-5 mb-3">All</Button>
                </li>
                {contents.categories.map((item, index) => (
                  <li key={index} className="d-inline-block px-2">
                    <Button variant='outline-light' onClick={() => handleClick(`[${item.row_key}]`)} className="rounded-pill fs-4 px-5 mb-3">{item.name}</Button>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} className="as-column-1 as-column-md-3 as-column-lg-3">
              {selectCategory !== '' ?
                contents.items.map((item, index) => (
                  index < maxItem && item.all_key.includes(selectCategory) ?
                    <div className='pin-card'>
                      <Card className='w-100 border-0 rounded-0 mb-5'>
                        <Card.Img className="rounded-0" variant="top" src={`${urlApi}/storage/article/${item.row_value}`} />
                        <Card.Title className='position-relative text-white bg-dark w-100 p-3 px-4 fs-5 m-0'>
                          <span className='position-absolute start-0 ps-4 fs-6' style={{ marginTop: '-50px' }}>{item.event_date}</span>
                          <span className='position-absolute end-0 pe-4 fs-6' style={{ marginTop: '-50px' }}>{item.trainer}</span>
                          <Link to={`/News/${item.row_slug}`} className='text-white text-decoration-none'>{item.name}</Link>
                        </Card.Title>
                      </Card>
                    </div>
                    : ''
                )) :
                contents.items.map((item, index) => (
                  index < maxItem ?
                    <div className='pin-card'>
                      <Card className='w-100 border-0 rounded-0 mb-5'>
                        <Card.Img className="rounded-0" variant="top" src={`${urlApi}/storage/article/${item.row_value}`} />
                        <Card.Title className='position-relative text-white bg-dark w-100 p-3 px-4 fs-5 m-0'>
                          <span className='position-absolute start-0 ps-4 fs-6' style={{ marginTop: '-50px' }}>{item.event_date}</span>
                          <span className='position-absolute end-0 pe-4 fs-6' style={{ marginTop: '-50px' }}>{item.trainer}</span>
                          <Link to={`/News/${item.row_slug}`} className='text-white text-decoration-none'>{item.name}</Link>
                        </Card.Title>
                      </Card>
                    </div>
                    : ''
                ))
              }
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col className='text-center'>
              <Link to='/News'>
                <Button variant='outline-light' className="rounded-pill fs-4 px-5">
                  More
                </Button>
              </Link>
            </Col>
          </Row>
        </>
      </Container>
    </section>
  )
}

HomeArticle.defaultProps = {
  contents: {
    categories: [],
    items: []
  }
}

export default HomeArticle