import React from 'react';
import { Container, Row } from 'react-bootstrap';
import ContentStyle from './ContentStyle';
import ContentText from './ContentText';
import ContentImage from './ContentImage';
import ContentLayout from './ContentLayout';
import ContentEmbedPDF from './ContentEmbedPDF';
import ContentDownload from './ContentDownload';
import DynamicComponent from './DynamicComponent';

const ContentDisplay = ({ contents, storage, lang }) => {
  const convertToCamelCase = (inputString) => {
    const words = inputString.split('_');
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    return words.join('');
  }

  return (
    <>
      <ContentStyle />
      {contents.map((content, index) => {
        let result;

        switch (content.row_class) {
          case 'text':
            result = <ContentText content={content.detail1} />;
            break;
          case 'image':
            result = <ContentImage src={storage + content.detail1} styleString={content.htm_style} />;
            break;
          case 'text-text':
            result = <>
              <ContentText content={content.detail1} size={6} />
              <ContentText content={content.detail2} size={6} />
            </>;
            break;
          case 'image-image':
            result = <>
              <ContentImage src={storage + content.detail1} size={6} layoutClass={'pb-5 pb-md-0'} />
              <ContentImage src={storage + content.detail2} size={6} />
            </>;
            break;
          case 'image-text':
            result = <>
              <ContentImage src={storage + content.detail1} size={6} />
              <ContentText content={content.detail2} size={6} />
            </>;
            break;
          case 'text-image':
            result = <>
              <ContentText content={content.detail1} size={6} />
              <ContentImage src={storage + content.detail2} size={6} />
            </>;
            break;
          case 'pdfviewer':
            result = <ContentEmbedPDF src={storage + content.detail1} />;
            break;
          case 'filedownload':
            result = <ContentDownload src={storage + content.detail1} lang={lang} />;
            break;
          case 'customsetup':
            result = <DynamicComponent directory={"CustomSetup"} componentName={convertToCamelCase(content.detail1)} contents={content.custom_setup} />;
            break;
          case 'customobject':
            result = <DynamicComponent directory={"CustomObject"} componentName={convertToCamelCase(content.detail1)} contents={content.custom_content} />;
            break;
          default:
            result = <ContentLayout content={content} storage={storage} />;
        }

        return (
          <>
            <Row key={index}
              className={content.htm_class === '' ? 'pb-5 px-0 px-sm-5 fs-5' : content.htm_class}>
              {result}
            </Row>
          </>
        );
      })}
    </>
  )
}

ContentDisplay.defaultProps = {
  contents: [],
  storage: '',
  lang: 'th'
}

export default ContentDisplay