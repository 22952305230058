import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const SectionLinkedinFeed = () => {
  useEffect(() => {
    // Create the script element dynamically
    const script = document.createElement('script');
    script.src = "https://static.elfsight.com/platform/platform.js";
    script.async = true;

    // Append the script to the document body
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <section id="client">
      <Container className='mt-5 mb-5'>
        <Row>
          <Col>
            <div class="elfsight-app-da3dcdfe-9c22-418c-990b-51381a073fbe" data-elfsight-app-lazy></div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default SectionLinkedinFeed