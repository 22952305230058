import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { urlApi } from '../../Constants/Global';

const Client = ({ contents }) => {
  return (
    <>
      <Container>
        <Row>
          {Array.isArray(contents) ? (
            contents.map((item, index) => (
              <Col key={index} md={3} className="p-0 p-lg-5 px-5">
                <img src={`${urlApi}/storage/media/${item.set1}`} alt="Partner" className='w-100' />
              </Col>
            ))) : ''}
        </Row>
      </Container>
    </>
  )
}

Client.defaultProps = {
  contents: []
}

export default Client