import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { urlApi } from '../Constants/Global';

function SectionEvent({ contents }) {
  const bgRadial = [
    'radial-gradient(circle at 0% 0%, rgb(249, 89, 60), #00423E 80%)',
    'radial-gradient(circle at 0% 0%, #1B2252, rgb(249, 89, 60) 80%)',
    'radial-gradient(circle at 0% 0%, #1B2252, #00423E 80%)'
  ]

  return (
    <section className='pt-5 pb-5'>
      <Container>
        <Row>
          <Col xs={12} xl={6}>
            <Row>
              {Array.isArray(contents) ? contents.map((item, index) => (
                index === 0 ?
                  <Col xs={12} className="mb-4" key={index}>
                    <div className='position-relative bg-img ratio-1x1' style={{ backgroundImage: item.row_value === null ? bgRadial[1] : `url(${urlApi}/storage/article/${item.row_value})` }}>
                      <Link to={`/Event/${item.row_slug}`}>
                        <div className='position-absolute bottom-0 text-white bg-dark w-100 p-3 px-4 fs-5'>
                          <span className='position-absolute start-0 ps-4 fs-6' style={{ marginTop: '-50px' }}>{item.event_date}</span>
                          <span className='position-absolute end-0 pe-4 fs-6' style={{ marginTop: '-50px' }}>{item.trainer}</span>
                          {item.name}
                        </div>
                      </Link>
                    </div>
                  </Col>
                  : index
              )) : ''}
            </Row>
          </Col>
          <Col xs={12} xl={6}>
            <Row>
              {Array.isArray(contents) ? contents.map((item, index) => (
                index > 0 && index <= 4 ?
                  <Col xs={12} md={6} className="mb-4" key={index}>
                    <div className='position-relative bg-img ratio-1x1' style={{ backgroundImage: item.row_value === null ? bgRadial[1] : `url(${urlApi}/storage/article/${item.row_value})` }}>
                      <Link to={`/Event/${item.row_slug}`}>
                        <div className='position-absolute bottom-0 text-white bg-dark w-100 p-3 px-4 fs-5'>
                          <span className='position-absolute start-0 ps-4 fs-6' style={{ marginTop: '-50px' }}>{item.event_date}</span>
                          <span className='position-absolute end-0 pe-4 fs-6' style={{ marginTop: '-50px' }}>{item.trainer}</span>
                          {item.name}
                        </div>
                      </Link>
                    </div>
                  </Col>
                  : ''
              )) : ''}
            </Row>
          </Col>
        </Row>
        <Row>
          {Array.isArray(contents) ? contents.map((item, index) => (
            index > 4 ?
              <Col xs={12} md={6} xl={3} className="mb-4" key={index}>
                <div className='position-relative bg-img ratio-1x1' style={{ backgroundImage: item.row_value === null ? bgRadial[1] : `url(${urlApi}/storage/article/${item.row_value})` }}>
                  <Link to={`/Event/${item.row_slug}`}>
                    <div className='position-absolute bottom-0 text-white bg-dark w-100 p-3 px-4 fs-5'>
                      <span className='position-absolute start-0 ps-4 fs-6' style={{ marginTop: '-50px' }}>{item.event_date}</span>
                      <span className='position-absolute end-0 pe-4 fs-6' style={{ marginTop: '-50px' }}>{item.trainer}</span>
                      {item.name}
                    </div>
                  </Link>
                </div>
              </Col>
              : ''
          )) : ''}
        </Row>
      </Container>
    </section>
  )
}

SectionEvent.defaultProps = {
  contents: []
}

export default SectionEvent