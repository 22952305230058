// version 0.3
// add - useParam variable

import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router";
import { urlApi, baseName, apiEndpointMapping } from '../Constants/Global';

const ObjRouteManager = (props) => {
  // State to store the fetched data
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState(null);

  // Get the current route path
  const fullPath = window.location.pathname;
  const currentPath = fullPath.replace(baseName, '');
  const pathSplit = currentPath.split('/');
  const pathLength = pathSplit.length;
  const path = pathLength === 1 ? '/' : '/' + pathSplit[0];
  const param = pathLength > 1 ? pathSplit[1] : '';
  const apiMapped = apiEndpointMapping[`${path}`];
  const apiEndpoint = (pathLength > 1 ? '../' : '') + urlApi + '/?module=' + apiMapped?.module + "&call=" + (param !== '' ? apiMapped?.callParam : apiMapped?.call) + (param !== '' ? '&param=' + param : '');

  console.log("ObjRouteManager", path, apiMapped, fullPath, pathLength, apiEndpoint);

  useEffect(() => {
    // Use the route path to determine the API endpoint
    // const apiEndpoint = apiEndpointMapping[path];

    // Fetch data from your API here
    // You can use the Fetch API or Axios for this
    if (prevLocation === null || prevLocation.pathname !== location.pathname) {
      console.log("fetch new content !");
      fetch(apiEndpoint)
        .then((response) => response.json())
        .then((result) => {
          console.log('result', result);
          props.changeHandler(result.results);
        })
        .catch((error) => console.error('Error fetching data:', error));
    }
    setPrevLocation(location);
  }, [location, prevLocation, apiEndpoint, props]);

  return <>{props.children}</>
};

export default ObjRouteManager;